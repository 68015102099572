.header{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #7a7a7a;
}
.Projectsmain2{
    text-align: center;
}
.portfolio{
    text-align: center;
    color: #7a7a7a;
}
.Projects-heading1{
    color:#EB595F;
    text-align: center;
    font-size: 1.3rem;
    padding-bottom: 1rem;
}
.Projects-subtext{
    color:#7a7a7a;
    font-size: 0.9rem;
}
.card{
    background-color: #dedede;
    padding: 1rem;
    margin:0 1rem 1rem 0;
} 

.Service-name1{
    padding-top: 0.5rem;
    text-align: left;
    color:#EB595F;
    font-size: 0.9rem;
}