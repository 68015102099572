.Policy-Headingmain{
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    color:#EB595F;
    padding: 1.5rem 0;
}
.Heading{
    text-align: center;
    font-size: 1rem;
    color:#0B2E33;
    font-weight: 600;
    padding-top: 1.5rem;
}
.All-para{
    font-size: 0.9rem;
    color:#7a7a7a;
    line-height: 1.5rem;
}