@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}