.footer{
    background-color: #dedede;
    color:#0B2E33;
    padding: 1.5rem;
    justify-content: center;
    text-align: left;
}
.container{
    align-content: space-between;
}
.footerlogo{
    width:2.5rem;
    padding-bottom: 1rem;
}
.ft-1 p{
    font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: 0.5px;
    color:#7A7A7A;
}
.social-icons a {
padding:1rem 0rem;
text-align: center;
align-items: center;
align-content: center;
margin:0 0.4rem;
color:#7A7A7A;
font-size: 0.9rem;
}
.social-icons a:hover{
    color:#EB595F;
}
 .registration{
    padding: 0.5rem 0rem;
    font-size: 0.8rem;
    line-height: 1.2rem;
    letter-spacing: 0.3px;  
    font-weight: 600;
}
.ft-3 h5{
 font-weight: 600;
 font-size: 1rem;
}

.address-text{
    padding: 0.5rem 0rem;
    font-size: 0.8rem;
    line-height: 1.2rem;
    letter-spacing: 0.3px; 
    color:#7A7A7A;
}
span{
    font-size: 0.9rem;
    margin-right: 0.3rem;
    text-align: center;
}
.Footer-phone{
    padding-top: 0.5rem;
    font-size: 0.9rem;
    line-height: 1rem;
    letter-spacing: 0.5px;
    color:#7A7A7A;
}
.ft-4 h5{
    font-weight: 600;
    font-size: 1rem;
   }
   .contactus-text {
       padding: 0.5rem 0rem;  
   }
   .footer a{
    /* text-decoration:underline; */
    list-style-type: none;
    text-decoration: none;
    color:#7a7a7a;
    font-size: 0.9rem;
    font-weight: 500;
       }
       .footer a:hover{
        color:#EB595F;
       }
       .ft-6 .nav-links{
         padding-top: 1.5rem;
       }

