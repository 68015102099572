/* .pnf{
    display: flex;
    flex-direction: column;
    text-align: center;
    max-height: 80%;
    align-items: center;
    justify-content: center;
    margin: 7rem 0;
}
.pnr-image{
    width:20rem;
    padding: 1rem 0;
}
.pnf-title{
    font-size: 3.5rem;
    font-weight: 700;
    color: #293F19;
}
.pnf-heading{
    font-size: 1rem;
    font-weight: 500;
}
.pnf-btn{
    color:#293F19;
    border: 1px solid #293F19;
    text-decoration: none;
    padding:0.3rem 1rem;
    margin-top: 0.5rem;
    border-radius: 0.3rem;
    font-size: 0.9rem;
}
.pnf-btn:hover{
    background-color:#293F19;
    color:#fff;
    transition: 0.4sec ease-in-out;
}
@media(max-width:576px){
    .pnf{
        margin: 12rem 0;
    }
} */
.close-icon{
    color:#EB595F;
    font-size: 1rem;
}
.close-icon:hover{
    color:#a6a6a6;
}
.back{
    position: fixed;
    top: 2rem;
    right: 1.5rem;
    z-index: 99;
    color:#EB595F;
    text-decoration: none;
}
.back:hover{
    color:#a6a6a6;
}
 .Letstalk{
    padding-top: 7rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}
section .form-control1{
    color:#a6a6a6;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    background-color: #DEDEDE;
    border: 1px solid #b0b0b0;
    width:80%;
    justify-content: center;
    align-items: center; 
}